import {IActor} from '@/typings/IActor'
import router from '@/router'
import {store} from '@/store'

const shortNames = ['', 'K', 'M', 'B', 'T', 'Q', 'Qu', 'Sex']

export function getShortName(value: number) {
    let currentValue = value
    let index = 0

    while (currentValue > 1000) {
        currentValue /= 1000
        index++
    }

    return `${currentValue.toFixed(1)}${shortNames[index]}`
}

export async function redirectToChatbotPage(actor: IActor) {
    store.commit('app/setShowNavigation', false)
    await router.push({ name: 'actor', params: { id: String(actor.web_name) } })
}

export async function sleep(ms: number) {
    new Promise((r) => setTimeout(r, ms));
}

export function fixMessage(str: string) {
    return str.replace(/([:?\.])([\`\d\(\)#-])/g, '$1\n$2')
}